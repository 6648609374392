//@flow
import React from "react";
import css from "./countdown.css";

type Props = {
  target: string
};
type State = {
  days: number,
  hours: number,
  minutes: number,
  seconds: number
};
class Countdown extends React.Component<Props, State> {
  target: Date;
  timerID: IntervalID;

  constructor(props: Object) {
    super(props);
    this.target = new Date(props.target);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  componentDidMount() {
    this.tick();
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  dateDiff(datepartInput: string, fromdate: Date, todate: Date) {
    let datepart = datepartInput.toLowerCase();
    var diff = todate - fromdate;
    var divideBy = {
      w: 604800000,
      d: 86400000,
      h: 3600000,
      n: 60000,
      s: 1000
    };

    let n = Math.floor(diff / divideBy[datepart]);
    let m = n > 0 ? n : 0;
    return m;
  }

  tick() {
    let now = new Date();
    let days = this.dateDiff("d", now, this.target);
    let hours = this.dateDiff("h", now, this.target) % 24;
    let minutes = this.dateDiff("n", now, this.target) % 60;
    let seconds = this.dateDiff("s", now, this.target) % 60;

    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    });
  }

  render() {
    return (
      <div className={css.countdown}>{this.state.days} days to go! xx</div>
    );
  }
}
export default Countdown;
