//@flow

import React from "react";
import Hls from "hls.js";
import css from "./tvplayer.scss.css";
import FontAwesome from "react-fontawesome";
import Player from "./player";
type Props = {
  hlsURL: string,
};
type State = { liveStreamOnAir: boolean, OffAirMessage: string };
export default class TVPlayer extends React.Component<Props, State> {
  state = { liveStreamOnAir: false, OffAirMessage: "" };

  async getIsChannelOnAir() {
    const url = `https://vidx-microservice-channel.service.indemand.digital/REM`;
    const res = await fetch(url);
    if (!res.ok) throw "Network fetching error - channel on air check";
    const data = await res.json();
    // const data = { LiveStreamOnAir: true, OffAirMessage: "TESTING MODE" };
    const onAir = data.LiveStreamOnAir;
    console.log("Live stream on air:", onAir);

    this.setState({ liveStreamOnAir: onAir, OffAirMessage: data.OffAirMessage });
  }

  componentDidMount() {
    this.getIsChannelOnAir();
    setInterval(() => this.getIsChannelOnAir(), 30000);
  }

  render() {
    // console.log(this.state);
    return !this.state.liveStreamOnAir ? <Player {...this.props} /> : null;
  }
}
