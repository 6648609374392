//@flow

import React from "react";
import Hls from "hls.js";
import css from "./tvplayer.scss.css";
import FontAwesome from "react-fontawesome";
import { endSession, startSession } from "../sessionReporting";
type Props = {
  hlsURL: string,
};
type State = { muted: boolean };
export default class TVPlayer extends React.Component<Props, State> {
  player: Object;
  state = { muted: true };
  hls: Object;

  // handleToggleMute() {
  //   this.setState({ muted: !this.state.muted });
  // }

  componentWillUnmount() {
    endSession();
    this.hls && this.hls.destroy();
  }

  componentDidMount() {
    var video = this.player;
    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // If HLS is natively supported, let the browser do the work!
      video.src = this.props.hlsURL;
      video.addEventListener("loadedmetadata", function () {
        video.play();
      });
    } else if (Hls.isSupported()) {
      // If the browser supports MSE, use hls.js to play the video
      this.hls = new Hls();
      this.hls.loadSource(this.props.hlsURL);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else {
      alert("Please use a modern browser to play the video");
    }
    video.addEventListener("play", () => {
      startSession(this.props.hlsURL);
      window.currentvideoplayer = this.player;
    });
    video.addEventListener("pause", () => endSession());
  }

  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        <video className={css.homevideo} controls={false} ref={(player) => (this.player = player)} autoPlay={true} muted={true} playsInline={true} loop={true} />
      </React.Fragment>
    );
  }
}
