// @flow
import React from "react";
import ReactDOM from "react-dom";

import Countdown from "lib/countdown";
import VideoBackground from "lib/tvplayer-frontpage";

import { load, doscroll } from "lib/commonloads";

load();

let countdown = document.getElementById("react-countdown");
if (countdown) ReactDOM.render(<Countdown target="2020-09-12T12:00:00" />, countdown);

let vid = document.getElementById("videobackground");
if (vid) ReactDOM.render(<VideoBackground hlsURL="https://d2vz0x5krocupv.cloudfront.net/vidx/7TL79WMa/playlist.m3u8" />, vid);

// const title = document.getElementById('titlepanel');

// window.addEventListener("scroll", () => {
//   doscroll(title, 15, 0.15);
// });
