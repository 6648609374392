exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tvplayer-scss__snes___3nW0X{font-family:\"nko\",sans-serif}.tvplayer-scss__twobit___1YkyT{font-family:\"ubuntu\",sans-serif}.tvplayer-scss__hands___37uHe{font-family:\"comic\",sans-serif}.tvplayer-scss__homevideo___2DmA1{position:absolute;width:100%;-ms-transform:translate(-50%);transform:translate(-50%)}", ""]);

// Exports
exports.locals = {
	"snes": "tvplayer-scss__snes___3nW0X",
	"twobit": "tvplayer-scss__twobit___1YkyT",
	"hands": "tvplayer-scss__hands___37uHe",
	"homevideo": "tvplayer-scss__homevideo___2DmA1"
};